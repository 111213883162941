import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import eachserviceStyles from "../styles/eachservice.module.scss"
import Layout from "../components/layout"


const CommercialCleaningPage = ((props) => {
  return (
    <Layout>
      <div className={eachserviceStyles.maindiv}>
        <div className={eachserviceStyles.headerdiv}>COMMERCIAL CLEANING</div>
        <div className={eachserviceStyles.imagediv}>
          <Img className={eachserviceStyles.imagetag} fluid={props.data.commercialCleaning.childImageSharp.fluid} alt="domesticCleaning"/>
        </div>
        <div className={eachserviceStyles.textdiv}>
          We offer specialised commercial or residential apartment complex cleaning services and management in and around Gauteng And Rustenburg. 
          We assist property owners and body cooperates to save their precious time and money by managing their apartment complexes by offering and implementing our superior apartment cleaning services. 
          We understand that keeping apartment buildings and complexes clean is important so as to maintain hygiene and health standards. 
          Furthermore, if you want your residents to take care of their own personal spaces it is Important to ensure all communal areas are clean at all times. 
          We create customized cleaning plans and schedules depending on each of the apartment complex needs and requirements. 
          Complex cleaning services can be a once off deep cleaning or frequent maintenance cleaning. 
          Contact us now to find out how we can assist with your cleaning requirements.
          We offer commercial cleaning services for offices, schools, churches, shopping malls and other commercial business premises. 
          Our commercial premises cleaning can be once-off thorough cleaning or contract janitorial services at an agreed frequency depending on our clients requirements. 
          We have specially trained and professional staff who will always deliver cleaning services to your satisfaction. 
          You can outsource your whole office cleaning to us. We can also clean your office windows and basement.
        </div>
      </div>
    </Layout>
  )
}
)

export default CommercialCleaningPage

export const pageQuery = graphql`
  query {
    commercialCleaning: file(relativePath: {eq: "CommercialCleaning.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`